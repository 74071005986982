import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notificationlist;user;notilist=[];searchWord
  p:number = 1;itemsPerPage = 50;
  constructor(private _dataService: ApiService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.getNotificaltionlist();
  }
   getNotificaltionlist(){
    this.notificationlist = {token: this.user.token }
      this._dataService.notificationList(this.notificationlist).subscribe(data=>{
        this.notilist=data.json();
      })
   }
}
