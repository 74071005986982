import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service'
import Swal from 'sweetalert2'
declare var $: any
@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {

  constructor(private _dataService: ApiService, private router: Router, private route: ActivatedRoute) { }
  logindisabled;lg_password;lg_password_c
  passwordtoken;myresponse;errormsg;errorpassword
  ngOnInit() {
    this.passwordtoken = this.route.snapshot.paramMap.get('id');
    $("#passwordform").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

    $("#showmsg").hide();

  }

  setpassword() {
    if (!$("#passwordform").valid()) {
    }
    else {
      if(this.lg_password==this.lg_password_c){
      this.logindisabled = true;
      this._dataService.apirequest('setnewpassword', { password: this.lg_password, cpassword: this.lg_password_c, 'passwordtoken': this.passwordtoken }).subscribe(data => {
        this.myresponse = data.json();
       
        this.logindisabled = false;
        if (this.myresponse.success) {
          localStorage.setItem('successmsg','Password updated successfully');
          this.router.navigate(['/']);
        }
        else {
          this.errormsg = this.myresponse.message;
          this.errorpassword = true;
        }
        setTimeout(() => {
          this.errorpassword = false;
        }, 3000);

      });
    }else{
      $("#showmsg").show();
     setTimeout(() => {
      $("#showmsg").hide();
     }, 2000);
    }
    }
  }

}
