import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ApiService } from './api.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { HeaderComponent } from './header/header.component';
import { ClientsComponent } from './clients/clients.component';
import { RatingscaleComponent } from './ratingscale/ratingscale.component';
import { NumberDirective } from './directive/number.directive';
import { SearchByPipe } from './pipe/search-by.pipe';
import { CompetenciesComponent } from './competencies/competencies.component';
import { RolelistComponent } from './rolelist/rolelist.component';
import { SubadminsComponent } from './subadmins/subadmins.component';
import { FooterComponent } from './footer/footer.component';
import { SurveyComponent } from './survey/survey.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SearchbyfieldPipe } from './pipe/searchbyfield.pipe';
import { VerificationComponent } from './verification/verification.component';
import { NotificationsComponent } from './notifications/notifications.component';
import {TimeAgoPipe} from 'time-ago-pipe';
import { SurveydetailsComponent } from './surveydetails/surveydetails.component';
import {TooltipModule} from "ngx-tooltip";
import { SurveyReportComponent } from './survey-report/survey-report.component';
import { SurveydetailedreportComponent } from './surveydetailedreport/surveydetailedreport.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { QuillModule } from 'ngx-quill';
import { NotfoundComponent } from './notfound/notfound.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DymailerComponent } from './dymailer/dymailer.component';
import { CKEditorModule } from 'ng2-ckeditor';




const Routes = [
  { path: 'login', component: LoginComponent },
   { path: '', component: LoginComponent },
   { path: 'dashboard', component: DashboardComponent ,canActivate: [AuthGuard]},
   { path: 'clients', component: ClientsComponent ,canActivate: [AuthGuard]},
   { path: 'ratingscale', component: RatingscaleComponent ,canActivate: [AuthGuard]},
   { path: 'competencies', component: CompetenciesComponent ,canActivate: [AuthGuard]},
   { path: 'roles', component: RolelistComponent ,canActivate: [AuthGuard]},
   { path: 'subadmins', component: SubadminsComponent ,canActivate: [AuthGuard]},
   { path: 'survey', component: SurveyComponent ,canActivate: [AuthGuard]},
   { path: 'verification/:id', component: VerificationComponent },
   { path: 'notification', component: NotificationsComponent ,canActivate: [AuthGuard]},
   { path: 'surveydetails', component: SurveydetailsComponent ,canActivate: [AuthGuard]},
   { path: 'surveydetails/:id', component: SurveydetailsComponent ,canActivate: [AuthGuard]},
   { path: 'survey-report/:id/:clientid', component: SurveyReportComponent ,canActivate: [AuthGuard]},
   { path: 'surveydetailedreport/:clientid/:surveynumber', component: SurveydetailedreportComponent ,canActivate: [AuthGuard]},
   { path: 'accessdenied', component: NotfoundComponent ,canActivate: [AuthGuard]},
   { path: 'dymailer', component: DymailerComponent ,canActivate: [AuthGuard]},
   { path: 'help', component: FaqsComponent ,canActivate: [AuthGuard]},
   {path: '**',redirectTo: '/dashboard'}
  ]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    ClientsComponent,
    RatingscaleComponent,
    NumberDirective,
    SearchByPipe,
    CompetenciesComponent,
    RolelistComponent,
    SubadminsComponent,
    FooterComponent,
    SurveyComponent,
    SearchbyfieldPipe,
    VerificationComponent,
    NotificationsComponent,
    TimeAgoPipe,
    SurveydetailsComponent,
    SurveyReportComponent,
    SurveydetailedreportComponent,
    NotfoundComponent,
    FaqsComponent,
    DymailerComponent
  ],
  imports: [
     BrowserModule,
    FormsModule,ColorPickerModule,
    ReactiveFormsModule,QuillModule,
    RouterModule.forRoot(Routes), HttpModule,
    HttpClientModule,
    NgxPaginationModule,CKEditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    TooltipModule,NgxUiLoaderModule,
    NgCircleProgressModule.forRoot({
  // "animateTitle": false,
  "animationDuration": 1000,
  "showUnits": false,
  "showBackground": false,
  "clockwise": true,
  "startFromZero": false,
}) ],
  providers: [ApiService,AuthGuard],
  bootstrap: [AppComponent],
  exports: [SearchByPipe]

})
export class AppModule { }
