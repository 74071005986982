import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user;showClinet=[];subadminlist=[];surveyList=[];notilist
  subactivearr=[];subinactivearr=[];substatuslen
  surinactivearr=[];suractivearr=[];surstatuslen;notificationlist; 
  rolelist;
  constructor(private _dataService: ApiService) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
  	this.showList();
  	this.subadminList();
  	this.showsurvey();
    this.getNotificaltionlist();
    this.getRoles();
  }
  statuslen=[];selectedactive;activearr=[];inactivearr=[];
  showList(){
    this.activearr=[];this.inactivearr=[];

  	this._dataService.dispAllClientAdminList({token:this.user.token}).subscribe(data=>{
      this.showClinet = data.json();
       this.statuslen=this.showClinet.map(function(item){
            return item.client_Status
        })
           for(var i=0;i<this.statuslen.length;i++){
            if(this.statuslen[i]=='active'){
              this.activearr.push(this.statuslen[i])
            }else{
              this.inactivearr.push(this.statuslen[i])              
            }
           }    
  	})
  }

  getRoles() {

    this._dataService.roleList({ user_id: this.user.user.response._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
   
    })
  }

checkAddPermission(add){
      if(this.rolelist != undefined){
       var view = this.rolelist[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkViewPermission(view){
      if(this.rolelist != undefined){
       var view = this.rolelist[0].role[0].view.indexOf(view);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

  getNotificaltionlist(){
    this.notificationlist = {token: this.user.token }
      this._dataService.notificationList(this.notificationlist).subscribe(data=>{
        this.notilist=data.json();
      })
   }

  subadminList() {
    this._dataService.subadminList({ user_id: this.user.user.response._id, token: this.user.token }).subscribe(data => {
      this.subadminlist = data.json();
      this.substatuslen=this.subadminlist.map(function(item){
        return item.status
    })
       for(var i=0;i<this.substatuslen.length;i++){
        if(this.substatuslen[i]=='active'){
          this.subactivearr.push(this.substatuslen[i])
        }else{
          this.subinactivearr.push(this.substatuslen[i])              
        }
       }  
    })
  }
  
  showsurvey(){
    this._dataService.dispAllSurveyList({token:this.user.token}).subscribe(data=>{
      this.surveyList= data.json();
      this.surstatuslen=this.surveyList.map(function(item){
        return item.survey_status
    })
       for(var i=0;i<this.surstatuslen.length;i++){
        if(this.surstatuslen[i]=='active'){
          this.suractivearr.push(this.surstatuslen[i])
        }else{
          this.surinactivearr.push(this.surstatuslen[i])              
        }
       } 
      });
}
}
