import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchbyfield'
})
export class SearchbyfieldPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any[] {
    if(value && value!='')
    {
    if (!items) return [];
    return items.filter(it => it[field] == value);
    }
    else
    {
      return items; 
    }
  }
}
