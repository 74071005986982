import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service'
import Swal from 'sweetalert2'
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  successmsg;successinfo
  constructor(private _dataService: ApiService, private router: Router) { }
  today = new Date();

  ngOnInit() {
       $("#forgotpassword").hide();
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/dashboard']);
    } 
    $("#loginback").hide();
    $("#submitemail").hide();
    $("#showmsg").hide();
    if (localStorage.getItem('successmsg')) {
      this.successmsg = localStorage.getItem('successmsg');
      this.successinfo = true;
      localStorage.removeItem('successmsg');
    }
  }
  myresponse;
  plceholder="Enter a Email";
  logtitle="Login";
  doLogin(data,type) {
     $("#forgotpassword").hide();
   
if(type=='login'){
    this._dataService.userLogin({ username: data.form.value.lg_username, password: data.form.value.lg_password }).subscribe(data => {
      this.myresponse = data;

      if (data[0].success) {

        this.router.navigate(['/dashboard']);
      }
      else {

        Swal('Oops...', data[0].message, 'error')
      }
    },
      function (err) {

        Swal('Oops...', err, 'error')
      })
    }else{
        $("#forgotpassword").hide();
    this._dataService.forgotLoginPass({ email: data.form.value.lg_username}).subscribe(data => {
      this.myresponse = data.json();
      if (this.myresponse[0].status) {
        Swal('Success', "Check your Inbox for email with a link to reset your password. If you haven't received it,check once in your Junk/Spam folder as well.", 'success');
        $("#showmsg").show();
        setTimeout(() => {
          $("#showmsg").hide();
        }, 3000);
      }
      else {
        Swal('Oops...', this.myresponse[0].message, 'error')
      }
      this.backlogin();
    });
    }
  }
  forgotpass(data){
    data.form.value.lg_password =""; 
   
   $("#lg_password").hide();
   $("#login").hide();
   $("#forgotpass").hide();
   $("#forgotpassword").show();
   $("#loginback").show();
   this.plceholder="Enter a Email"
   this.logtitle="Submit";

  }


  backlogin(){
    $("#lg_password").show();
    $("#forgotpass").show();
    $("#login").show();
    $("#forgotpassword").hide();
    $("#loginback").hide();
   this.plceholder="Enter a Email"
   this.logtitle="Login";


  }

}
