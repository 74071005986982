import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { Router,ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-competencies',
  templateUrl: './competencies.component.html',
  styleUrls: ['./competencies.component.css']
})
export class CompetenciesComponent implements OnInit {

  user;
  competency_name: String; response;
  competency_desc;selected_comp;
  searchtext;searchtextbyquestion;selectedItemsList = [];
  competencylist: any = []; p: number = 1;data_id = '';pointarr = [0];questioninfo = [];
  questionlist = [];  questionind= null; dropdownList = [];dropdownSettings;
  rolelistPermission; rolelist;
  constructor(private apiService: ApiService ,private router: Router) {}
  loginuser;questionlists=[];quesid
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.loginuser=this.user.user.response.adminname
    this.getlist();
    // $("#hidedelete").show();

    $("#competency_form").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

    $("#feedback_from").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'competency_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.getRoles();
  }


    getRoles() {
    this.apiService.roleList({ user_id: this.user.user._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json();
      this.rolelistPermission = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
      
       if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].view.indexOf('competencies');
       if(view == -1){
         this.router.navigate(['/accessdenied']);
       }
      }     
    })
  }
  getlist() {
    this.apiService.apirequest('/getAllCompetency',{ token: this.user.token }).subscribe(data => {
      if (data.json().status) {
        this.competencylist = data.json().data;
        this.dropdownList = data.json().data;
      }
    })
  }
  getquestionlist() {
    this.quesid='';
    this.questionlists=[];
    this.apiService.apirequest('/getAllquestion',{ token: this.user.token,data_id:this.data_id,'type':'list' }).subscribe(data => {
      if (data.json().status) {
        this.questionlist = data.json().data;
        this.questionlist.forEach(element => {
          this.questionlists.push(element)
        });
      }
    })
  }
  addmorequestion()
  {
    this.pointarr.push(this.pointarr.length);
  }
  removequestion(index)
  {
    this.pointarr.splice(index, 1);
  }
  cleardata()
  {
    this.competency_name = "";
    this.competency_desc = "";
    this.data_id = "";  
  
  }



  clearques(){
    this.questionind= null;
    this.questioninfo=[];
    this.pointarr = [0];
    $("#editrem").show();

  }
  allquestionstag
  activate_competency(event,selected,ind)
  {
    this.quesid='';
    //$("#hidedelete").show();
    this.questionlists=[]
   $('.newsale_box').find('.active_comp').removeClass("active_comp");
   $(event.target).addClass('active_comp');
   this.selected_comp = selected;
   this.data_id = selected._id;
   this.getquestionlist();
  
   this.selectedItemsList = this.dropdownList.filter(function(competencies) { 
    if(selected.tagged_competency.indexOf(competencies._id) >-1) { 
       return true; 
     } else { 
        return false; 
       }  
      });

//   var myArr = this.dropdownList;

// var index = myArr.findIndex(function(o){
//      return o._id === selected._id;
// })

// if (index !== -1) myArr.splice(index, 1);
// setTimeout(() => {
//   this.dropdownList=myArr
// }, 100);

  }
  editcompetency(data)
  {
  this.competency_name = data.competency_name;
  this.competency_desc = data.competency_desc;
  this.data_id = data._id;
  }
  submitmodel() {
    if (!$("#competency_form").valid()) {
      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
      $("#addModal").modal("show");
    }else{
    this.apiService.apirequest('/addCompetencies',{ token: this.user.token,userlogin:this.loginuser,data_id:this.data_id,competency_name: this.competency_name, competency_desc: this.competency_desc }).subscribe(data => {
      this.response = data.json();
      $("#popupmodel").modal("hide");
      if (this.response.status) {
        Swal('Success', this.response.message, 'success');
      }
      else {
        Swal('Oops...', this.response.message, 'error')
      }
      this.selected_comp = '';
      this.getlist();
      this.competency_name = '';this.competency_desc = '';
    })
  }
  }
  submitquestion() {
    if (!$("#feedback_from").valid()) {
      $("#feedback_from").disabled=true;

      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
      $("#addModal").modal("show");
    }else{
      this.questionlists=[]

      this.questioninfo.forEach(element => {
     
      this.apiService.apirequest('/addCompetenciesquestion',{ token: this.user.token,userlogin:this.loginuser,data_id:this.data_id,quesid:this.quesid,questioninfo: element,quesindex:this.questionind,selectedcom:this.selectedItemsList }).subscribe(data => {
      this.response = data.json();

      $("#feedbackquestion").modal("hide");
      if (this.response.status) {

        Swal('Success', "Feedback Question Added Successfully.", 'success');

      }
      else {
        Swal('Oops...', this.response.message, 'error')
      }
  
    });
  })

  setTimeout(() => {
    this.getquestionlist();
  }, 500);

      this.pointarr = [0];
      this.questioninfo = [];
    //  this.questionlists=[]

    
  }
  }
  deletecompetency(del_id){
    Swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteCompetency({ token: this.user.token, userlogin:this.loginuser,del_id: del_id }).subscribe(data => {
          this.response = data.json();
          this.getlist();  
          if (this.response.status) {
            Swal('Deleted!', this.response.message, 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error');
          }
        })
      }
    })
    setTimeout(() => {
     this.selected_comp = '';
       }, 1000);

  };

  deletequestions(data,ind){
    Swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteQuestions({ token: this.user.token,userlogin:this.loginuser, del_id: data._id,index:ind }).subscribe(data => {
          this.response = data.json();
          this.getquestionlist();
          if (this.response.status) {
            Swal('Deleted!', "Feedback Question Deleted Successfully.", 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error');
          }
        })
      }
    })
    

  };

  editquestions(data,ind,id)
  {
    this.questionind = ind;
    this.pointarr = [0];
    this.quesid=data._id;
    $("#editrem").hide();
    this.questioninfo.push(data.competency_question)
  //    this.questioninfo = this.questionlist.filter(function(competenciesques) { 
  //   if(data.indexOf(competenciesques) >-1) { 
  //     return true; 
  //   } else { 
  //      return false; 
  //     }  
  // })
}

  checkAddPermission(add){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkEditPermission(edit){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].edit.indexOf(edit);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkDelPermission(del){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].del.indexOf(del);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

}
