import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-subadmins',
  templateUrl: './subadmins.component.html',
  styleUrls: ['./subadmins.component.css']
})
export class SubadminsComponent implements OnInit {
  user; rolelist; admin_name; data_id; subadminlist=[]; password; cpassword;searchTerm
  email; phone_number; department; role_type; status; response; p: number = 1;itemsPerPage = 50;loginuser
  constructor(private apiService: ApiService , private router: Router) { }
  passhide = true;getstatus ='';
  cpasshide = true;
  rolelistPermission;
  editFlag;
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.loginuser=this.user.user.response.adminname
    this.getRoles();
    this.subadminList();
    $("#subadmin_form").validate({
			errorPlacement: function (error, element) {
			  return true;
			}
		  });
  
  }
  getRoles() {
    this.apiService.roleList({ user_id: this.user.user._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json();
      this.rolelistPermission = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
       if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].view.indexOf('subadmins');
       if(view == -1){
         this.router.navigate(['/accessdenied']);
       }
      }     
    })
  }
  subadminList() {
    this.apiService.subadminList({ user_id: this.user.user._id, token: this.user.token }).subscribe(data => {
      this.subadminlist = data.json();
    })
  }
  addsubadmin() {
    this.data_id = '';
    this.passhide = true;
    this.cpasshide = true;
    this.admin_name = "";
    this.email = "";
    this.password = "";
    this.cpassword = "";
    this.phone_number = "";
    this.department = "";
    this.role_type = "";
    this.status = "";
  }
  submitsubadmin() {
    if (!$("#subadmin_form").valid()) {
      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
      $("#addModal").modal("show");
      }else{
    if (this.editFlag != 'edit') {
      this.apiService.addSubadmins({ token: this.user.token,userlogin:this.loginuser, data_id: this.data_id, admin_name: this.admin_name, email: this.email, password: this.password, phone: this.phone_number, department: this.department, roletype: this.role_type, status: this.status ,flag : 'add'}).subscribe(data => {
        this.response = data.json();
        if (this.response.status) {
          Swal('Success', this.response.message, 'success');
          $("#model_popup").modal("hide");

        }
        else {
          Swal('Oops...', this.response.message, 'error')
          $("#model_popup").modal("show");

        }
        this.subadminList();
      })
    } else {
     this.apiService.addSubadmins({ token: this.user.token,userlogin:this.loginuser, data_id: this.data_id, admin_name: this.admin_name, email: this.email, phone: this.phone_number, department: this.department, roletype: this.role_type, status: this.status }).subscribe(data => {
        this.response = data.json();
        if (this.response.status) {
          Swal('Success', this.response.message, 'success');
          $("#model_popup").modal("hide");

        }
        else {
          Swal('Oops...', this.response.message, 'error')
          $("#model_popup").modal("show");

        }
        this.subadminList();
      })
    }
  }
}
  role_id;
  edit(data , type) {
    this.editFlag = type;
    this.passhide = false;
    this.cpasshide = false;
    this.data_id = data._id;
    // this.role_id  = data.role_id;
    this.admin_name = data.adminname;
    this.email = data.email;
    this.role_type = data.role_id;
    this.phone_number = data.phone;
    this.department = data.department;
    this.status = data.status;
  }


  delete(del_id) {

    Swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteSubadmin({ token: this.user.token, del_id: del_id,userlogin:this.loginuser }).subscribe(data => {
          this.response = data.json();
          this.subadminList();
          if (this.response.status) {
            Swal('Deleted!', this.response.message, 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error');
          }
        })
      }
    })
  }

checkAddPermission(add){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkEditPermission(edit){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].edit.indexOf(edit);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkDelPermission(del){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].del.indexOf(del);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

}
