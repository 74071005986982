import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { ViewChild } from '@angular/core'
import Swal from 'sweetalert2'
import { Router, RouterModule, ActivatedRoute } from '@angular/router';

declare var $ : any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  clientForm: FormGroup; data_id = ''; oriSurveyname; surveylist; oriSurveyarr = []; finSurveyarr = [];

  searchWord; response; getstatus = ''; showimgerr
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  rolelistPermission;
  rolelist;
  userdata = {
    client_company_name: "", client_name: "", client_password: "", client_domainName: "",
    client_email: "", client_Status: "", client_address: "", client_logo: ""
  };
  deletedata;
  @ViewChild("fileInput") fileInput;
  @ViewChild("fileInput1") fileInput1;
  constructor(private _dataService: ApiService,private fb: FormBuilder, private router: Router) {
    this.clientForm = fb.group({
      // '_id' : [null, Validators.required],
      'Client_company_name' : [null, Validators.required],
      'Client_name' : [null, Validators.required],
      'Client_domainName': [null, Validators.required],
      'RatingScale_id': [null, Validators.required],
      // 'Competency_id': [null, Validators.required],
      'Client_email': new FormControl('', [ 
        Validators.required,
        Validators.pattern("[^ @]*@[^ @]*") 
    ]),
      'Client_address': [null, Validators.required],
      'Client_Status': ['', Validators.required],
      // 'Client_profileImg': [null, Validators.required],
      'Client_logo': [null, Validators.required],
      'choose_survey': [null, Validators.required],
      
    });

   }
   p:number = 1;
   itemsPerPage = 50;
   user;loginuser
  ngOnInit() {
    this.showimgerr = false

    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.loginuser=this.user.user.response.adminname
    this.showList();
    //this.competencyList();
    this.ratingList();
    this.showsurvey();
    this.getRoles();
    
 $("#clientForm").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'survey_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
showClinet = []; showRating;showCompetency
  showList(){
  	this._dataService.dispAllClientAdminList({token:this.user.token}).subscribe(data=>{
  		this.showClinet = data.json();
  	})
  }
    showsurvey(){
      this._dataService.dispAllSurveyList({token:this.user.token}).subscribe(data=>{
        this.dropdownList= data.json();
        });
    } 
  ratingList(){
  	this._dataService.dispAllRatingList({token:this.user.token}).subscribe(data=>{
  		this.showRating = data.json();
  	})
  }


getRoles() {
    this._dataService.roleList({ user_id: this.user.user._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json();
      this.rolelistPermission = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
    
       if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].view.indexOf('clients');
       if(view == -1){
         this.router.navigate(['/accessdenied']);
       }
      }     
    })
  }



  fileupload;fileuploadlogo;
  
  addClientAdmin(clientdata){ 
    if (!$("#clientForm").valid()) {
      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
      $("#addModal").modal("show");
    }else{
    clientdata.clientImage= this.fileupload;
    clientdata.clientLogo= this.fileuploadlogo;
   this._dataService.addClientAdmin({token:this.user.token,userlogin:this.loginuser,data:clientdata,dataid:this.data_id}).subscribe(data=>{
   

    this.response = data.json();
    if (this.response.status) {
      Swal('Success', this.response.message.Success, 'success');
      this.hidework();

    }
    else {
      $("#addModal").modal("show");

      Swal('Oops...', this.response.message, 'error');
    }
      this.showList();
      this.fileuploadlogo ="";
  	})
  }
}
  modeltitle;
  submit;btnadd;btnedit =  false;  
  ;title;imgtitle
  add(){
    $('#defaultimg').show();
    this.data_id = '';
    this.userdata = {
      client_company_name: "", client_name: "", client_password: "", client_domainName: "",
      client_email: "", client_Status: "", client_address: "", client_logo: null
    }; this.modeltitle = "Add";
    this.submit = "Save";
    this.btnadd = true;
    this.btnedit = false;
    this.selectedItems = [];
    this.title = "Add New Client";
    $('#imgpre').hide();

  }

  imageSrc;
  readFile1(files: FileList) {

    if (files[0].type == 'image/jpeg' || files[0].type == 'image/png' || files[0].type == 'image/gif') {
      $('#defaultimg').hide();
      this.fileuploadlogo = files.item(0);
      this.imageSrc = this.fileuploadlogo.name
      $('#imgpre').show();
    } else {
      this.showimgerr = true
      setTimeout(() => {
        this.showimgerr = false
      }, 2000);
    }

  }

  hidework() {
    $('#addModal').hide();
    $('body').removeClass().removeAttr('style');$('.modal-backdrop').remove();
  }
  edit(x){
    $('#imgpre').hide();
    this.userdata=x;
    this.modeltitle="Edit";
    this.submit="Update";
    this.btnadd=false;
    this.btnedit=true;
    this.data_id = x._id;
    this.title="Update Client";

    this.selectedItems = this.dropdownList.filter(function(competencies) { 
      if(x.survey_id.indexOf(competencies._id) >-1) { 
         return true; 
       } else { 
          return false; 
         }  
        });
    $(".error").removeClass("error");
    $('#defaultimg').show();

  }
  delete(del_id) {
    Swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._dataService.delete({ token: this.user.token, userlogin: this.loginuser, del_id: del_id }).subscribe(data => {
          this.response = data.json();
          this.showList();
          if (this.response.status) {
            Swal('Deleted!', this.response.message, 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error');
          }
        })
      }
    })
  };

  deleteworkmodal() {
    $('#deleteModal').hide();
    $('body').removeClass().removeAttr('style'); $('.modal-backdrop').remove();
  }
  mouseEnter(data) {
      this.oriSurveyarr = []
      data.forEach(element => {
             this.oriSurveyname = element.survey_name
            this.oriSurveyarr.push(this.oriSurveyname)

    });
    return this.oriSurveyarr.join(',')

  }

checkAddPermission(add){
  
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkEditPermission(edit){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].edit.indexOf(edit);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkDelPermission(del){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].del.indexOf(del);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

}
