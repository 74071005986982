import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { Router,ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-ratingscale',
  templateUrl: './ratingscale.component.html',
  styleUrls: ['./ratingscale.component.css']
})
export class RatingscaleComponent implements OnInit {
  numbers; user;
  ratingscale_name: String; response;
  ratingscale_point;
  pointarr = [];
  mydata=[];
  ratingscalelist: any = []; p: number = 1;data_id = '';itemsPerPage = 50;
rolelistPermission;
rolelist;
  pointsdata = { point_name: {}, point_value: {},point_desc:{} };
  searchtext;loginuser
  constructor(private apiService: ApiService,private router: Router) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.loginuser=this.user.user.response.adminname
    this.getlist();
    $("#rating_scale").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

    this.getRoles();
  }
  changeobject(obj) {
    return Object.values(obj);
  }
  getlist() {
    this.apiService.listAllRatingScales({ token: this.user.token }).subscribe(data => {
      if (data.json().status) {
        this.ratingscalelist = data.json().data;
      }
    })
  }

  getRoles() {
    this.apiService.roleList({ user_id: this.user.user._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json();
      this.rolelistPermission = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
    
       if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].view.indexOf('settings');
       if(view == -1){
         this.router.navigate(['/accessdenied']);
       }
      }     
    })
  }

  addrating(){
    this.ratingscale_name="";
    this.ratingscale_point="";
    this.pointarr = [];
    this.data_id = '';

  }
  pointarray() {
    this.pointsdata  = { point_name: {}, point_value: {},point_desc:{} };
    if (this.ratingscale_point != "") {
      this.ratingscale_point = parseInt(this.ratingscale_point);
      this.pointarr = Array(this.ratingscale_point).fill(this.ratingscale_point).map((x, i) => i);
    }
    else {
      this.pointarr = Array(0).fill(0).map((x, i) => i);
    }
  }
  submitratingcale() {
    if (!$("#rating_scale").valid()) {
      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
      $("#addModal").modal("show");
    }else{
    this.apiService.addRatingScaleApi({ token: this.user.token,userlogin:this.loginuser,data_id:this.data_id,ratingscale_name: this.ratingscale_name, ratingscale_point: this.ratingscale_point, pointsdata: this.pointsdata }).subscribe(data => {
      this.response = data.json();
      $("#popupmodel").modal("hide");
      if (this.response.status) {
        Swal('Success', this.response.message, 'success');
      }
      else {
        Swal('Oops...', this.response.message, 'error')
      }
      this.getlist();
    })
  }
  }

  delete(del_id) {

    Swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteRatingScale({ token: this.user.token,userlogin:this.loginuser, del_id: del_id }).subscribe(data => {
          this.response = data.json();
          this.getlist();
          if (this.response.status) {
            Swal('Deleted!', this.response.message, 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error');
          }
        })
      }
    })
  }
  editdata(data)
  {
    this.data_id  = data._id;
    this.ratingscale_name = data.ratingscale_name;
    this.ratingscale_name = data.ratingscale_name;
    this.ratingscale_point = data.ratingscale_point;
    this.pointarray();
    this.pointsdata = data.pointsdata;
   
   
  }


  checkAddPermission(add){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkEditPermission(edit){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].edit.indexOf(edit);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkDelPermission(del){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].del.indexOf(del);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

}
