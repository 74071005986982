import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  curpassword; password; cpassword; user; curuser; notifi
  constructor(private _dataService: ApiService, public router: Router) { }
  loginusername; profileurl; profilenew; usercheck
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.curuser = this.user.user.response;
    this.usercheck = this.curuser.user_type;
    if (this.usercheck == "SA") {
      this.notifi = true;
    } else {
      this.notifi = false;
    }
    this.profileurl = (localStorage.getItem("profile_pic") != '') ? '/profileimage/' + localStorage.getItem("profile_pic") : '/profileimage/default.png';
    //    this.profileurl = (this.curuser.profile_pic!='') ? '/assets/clientimage/' + this.curuser.profile_pic : 'assets/images/default.png';

    this.loginusername = this.user.user.response.adminname
    $("#userprofileForm").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

    $("#pass_form").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

    this.getRoles();
  }
  adminlogout() {
    localStorage.removeItem('expire_check');
    this._dataService.adminlogout();
    this.router.navigate(['/login']);
  }
  changepass() {
    this.curpassword = "";
    this.password = "";
    this.cpassword = "";
  }
  response
  passwordreset() {
    if (!$("#pass_form").valid()) {
      Swal('Oops...', "Please submit all mantatory fields as required.", 'error');
      $("#pass_popup").modal("show");
    } else {
      this._dataService.passwordReset({ user_id: this.user.user.response._id, token: this.user.token, currpassword: this.curpassword, newpass: this.password, conpass: this.cpassword }).subscribe(data => {
        this.response = data.json();
        if (this.response.status) {
          Swal('Success', this.response.message, 'success');
          $("#pass_popup").modal("hide");

        }
        else {
          Swal('Oops...', this.response.message, 'error');
          $("#pass_popup").modal("show");

        }
      })
    }
  }

  onprofileSubmit(userprofileForm: NgForm) {
    if (!$("#userprofileForm").valid()) {
    }
    else {
      var data = { userdata: userprofileForm.value, profileimage: this.profilenew, data_id: this.curuser._id, token: this.user.token, userprofileImg: this.user.user.response.profile_pic }
      //this.pageloader = true;
      this._dataService.apirequest('updateSubadmins', data).subscribe(data => {
        //   this.pageloader = false;
        this.response = data.json();
        if (this.response.status) {

          if (this.profilenew != '') {
            $('#profile_photo').attr('src', this.profilenew);
          }
          this.user.user.response = this.response.responsedata;
          localStorage.setItem('currentUser', JSON.stringify(this.user));
          if (this.response.result) {
            localStorage.setItem('profile_pic', this.response.result);
          }
          localStorage.setItem('adminname', this.user.user.adminname);
          this.loginusername = this.user.user.response.adminname

          $('#editprofile').modal('hide');
          Swal({ position: 'top-end', type: 'success', title: "Success", text: this.response.message, showConfirmButton: false, timer: 3000 })
        }
        else {
          Swal({ position: 'top-end', type: 'error', title: "Oops!", text: this.response.message, showConfirmButton: false, timer: 3000 })
        }
      });
    }

  }
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/gif') {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          $('#editprofilepic').attr('src', event.target.result);
          $('#editprofilepic').show();
          this.profilenew = event.target.result;

        }
        reader.readAsDataURL(event.target.files[0]);
      }
      else {
        $('#editprofilepic').attr('src', "");
        $('#editprofilepic').hide();
        this.profilenew = "";
      }
    }
  }
  rolelist;
  getRoles() {
    this._dataService.roleList({ user_id: this.user.user.response._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json().filter(x => { return x._id == this.user.user.response.roletype });

    })
  }
  checkViewPermission(view) {
    if (this.rolelist != undefined) {
      var view = this.rolelist[0].role[0].view.indexOf(view);
      if (view == -1) {
        return false;
      }
      else {
        return true;
      }
    }
  }

}

