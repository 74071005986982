import { Injectable } from '@angular/core';
import { Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
   constructor(private router: Router) { }
   checkUser;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
    {
        if (localStorage.getItem('expire_check'))
        {
            var record = JSON.parse(localStorage.getItem('expire_check'));
         
            if(new Date().getTime() > record.timestamp)
            {
                 localStorage.removeItem('currentUser');
                 localStorage.removeItem('expire_check');
                 this.router.navigate(['']);
                 return false;
            }else
            {
                   
                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['']);
        return false;
    
}
}
