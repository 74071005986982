import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  user; curent_userdata; today = new Date();

  get isLoggedIn() {
    if (localStorage.getItem("currentUser")) {
      this.user = JSON.parse(localStorage.getItem("currentUser"));
      if (!this.curent_userdata) {
        this.curent_userdata = JSON.parse(localStorage.getItem("currentUser")).dealer_details;
      }
      return true;
    }
    else {
      return false;
    }
  }


}
