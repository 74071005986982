import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as math from 'mathjs';
import 'rxjs/Rx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from "sweetalert2";
declare var $: any;
@Component({
  selector: 'app-survey-report',
  templateUrl: './survey-report.component.html',
  styleUrls: ['./survey-report.component.css']
})
export class SurveyReportComponent implements OnInit {
  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private ngxService: NgxUiLoaderService) { }
  user; clientname; survey_id; reportinfo = { data: { feedback_receiver_data: [], survey_name: '' }, clientinfo: { client_company_name: '', company_logo: '' } };
  detailed_report = [];
  is_survey_expired:boolean
  usernameDisplay;
  department_name;
  user_profileImage;
  search = "";
  rolearr = [];
  isClick = false;
  displayGrid;
  feedbackgiver;
  feedBackdata;
  rolearrvalue;
  survey_number;
  survey_name;
  introduction = { introduction: '', rating_summary: '', response_summary: '', introText: '', ratingText: '', responseText: '' };
  profile_chart = { profile_chart: '', competencyTitle: '' }
  parameterWiseReport = { parameterWiseReport: '', parameterTitle: '' }
  topStrengthdata = { topStrengthTitle: '' }
  developmentwindow = { developmentwindow: '', Title360: '' }
  closureText = { closureText: '', closureTitle: '' }
  questionText = {questionsTitle: '' }
  closureTitle
  competencyTitle;
  profilechart;
  parameterwise;
  degree;
  closure;
  questions;
  topStrengths;
  intro;
  profilechartStatus;
  parameterwiseStatus;
  windowStatus;
  closureStatus;
  downloaderurl = ''; response;
  typeCheck;
  loadingmsg: String = '';
  @ViewChild('downloadreport') downloadreport: ElementRef;
  ngOnInit() {
    this.ngxService.start();
    if (localStorage.getItem("currentUser")) {
      this.user = JSON.parse(localStorage.getItem("currentUser"));
    }
    this.route.params.subscribe(params => {
      this.clientname = params['clientid'];
      this.survey_id = params['id'];
    });
    this.apiService.apirequest('getclientanduserdata', { token: this.user.token, survey_id: this.survey_id, clientname: this.clientname, type: 'surveyinfo' }).subscribe(data => {
      this.response = data.json();
      console.log(this.response)
      if (this.response.data) {
        this.intro = this.response.data.introstatus;
        this.profilechart = this.response.data.profilechartStatus;
        this.parameterwise = this.response.data.parameterwiseStatus;
        this.degree = this.response.data.windowStatus;
        this.closure = this.response.data.closureStatus;
        this.topStrengths = this.response.data.topStrengths;
        this.questions = this.response.data.questions;
        this.introduction.introText = this.response.data.introText;
        this.introduction.ratingText = this.response.data.ratingText;
        this.introduction.responseText = this.response.data.responseText;
        this.introduction.introduction = this.response.data.introduction;
        this.introduction.rating_summary = this.response.data.rating_summary;
        this.introduction.response_summary = this.response.data.response_summary;
        this.profile_chart.profile_chart = this.response.data.competencyProfileChart;
        this.profile_chart.competencyTitle = this.response.data.competencyTitle;
        this.parameterWiseReport.parameterWiseReport = this.response.data.parameterWiseReport;
        this.parameterWiseReport.parameterTitle = this.response.data.parameterTitle;
        this.topStrengthdata.topStrengthTitle = this.response.data.topStrengthTitle;
        this.questionText.questionsTitle = this.response.data.questionsTitle;
        
        this.developmentwindow.developmentwindow = this.response.data.developmentwindow;
        this.developmentwindow.Title360 = this.response.data.Title360;
        this.closureText.closureText = this.response.data.closureText;
        this.closureText.closureTitle = this.response.data.closureTitle;
      }
      if (this.response.clientinfo) {
        this.reportinfo.clientinfo = this.response.clientinfo;
      }
      if (this.response.data) {
        this.reportinfo.data = this.response.data;
      }
      this.ngxService.stop();
    });
  }
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }],
        [{ 'color': [] }],
      ],
    },
  }
  saveCheck(type, data, status) {
    this.apiService.apirequest('addInstructionTosurvey', { token: this.user.token, survey_id: this.survey_id, "data": data, clientname: this.clientname, 'type': type, status: status }).subscribe(data => {
    });
  }
  saveReport(type, data, status) {
    this.typeCheck = type;
    this.loadingmsg = "Please Wait .........";
    // console.log(data,type);
    // addInstructionTosurvey
    this.apiService.apirequest('addInstructionTosurvey', { token: this.user.token, survey_id: this.survey_id, "data": data, clientname: this.clientname, 'type': type, status: status }).subscribe(data => {
      setTimeout(() => {
        this.loadingmsg = "Saved Successfully";
      }, 700);
      setTimeout(() => {
        this.loadingmsg = "";
      }, 2000);
    });
  }
  survey_start = new Date(); survey_end = new Date(); report_status = 'pending';
  getsurveyreports(data) {
    this.usernameDisplay = data.receiverinfo.user_name;
    this.department_name = data.receiverinfo.user_department;
    this.user_profileImage = data.receiverinfo.user_profileImg;
    this.survey_number = data.survey_number;
    this.survey_name = this.reportinfo.data.survey_name;
    this.survey_start = new Date(data.startdate);
    this.survey_end = new Date(data.enddate);
    var now = new Date();
  now.setHours(0,0,0,0);
  if (this.survey_end < now) {
   this.is_survey_expired = true
  } else {
   this.is_survey_expired= false
  }
    this.report_status = data.report_status;
    this.isClick = true;
    this.rolearr = []; this.rolearrvalue = [];
    console.log('report_sttausss', this.report_status,this.is_survey_expired);
    let api_value;
    if(this.report_status =='completed') {
      api_value = 'getsurveyreports'
    } else {api_value = 'getsurveyreports_expired_reports'}
    this.apiService.apirequest(api_value, { token: this.user.token, survey_id: this.survey_id, clientname: this.clientname, feedback_receiver_id: data.feedback_receiver_id, type: 'surveyinfo' }).subscribe(data => {
      this.detailed_report = data.json().data;
      if (this.detailed_report && this.detailed_report[0] && this.detailed_report[0].avg_all) {
        this.rolearr = Object.keys(this.detailed_report[0].avg_all);
        this.rolearrvalue = Object.values(this.detailed_report[0].avg_all);
      }
      $('#myModal_survey').modal('show');
    });
  }
  filterby = '';
  filterdata(type) {
    this.filterby = type;
  }
  downloadscorecard() {
    this.ngxService.start();
    this.apiService.apirequest('downloadscorecard', { 'actiontype': 'single', name: this.usernameDisplay, token: this.user.token, survey_number: this.survey_number, survey_name: this.survey_name, clientname: this.clientname }).subscribe(documents => {
      this.displayGrid = documents.json();
      if (this.displayGrid.status) {
        this.downloaderurl = this.displayGrid.data;
        setTimeout(() => {
          let el: HTMLElement = this.downloadreport.nativeElement as HTMLElement;
          el.click();
          this.ngxService.stop();
        }, 100);
      }
    })
  }
  ff = 0;
  downloadallscore(ff) {
    var datas = this.reportinfo.data.feedback_receiver_data[ff];
    if (datas) {
      this.scorecardrequest(datas);
      this.downloadallscore(ff + 1)
    }
    // this.ngxService.start();
    this.reportinfo.data.feedback_receiver_data.map(function (value, index) {
      if (value.report_status == 'published' || value.report_status == 'completed') {
      }
    })
  }
  scorecardrequest(value) {
    this.apiService.apirequest('downloadscorecard', { 'actiontype': 'multiple', name: value.receiverinfo.user_name, token: this.user.token, survey_number: this.survey_number, survey_name: this.survey_name, clientname: this.clientname }).subscribe(documents => {
    })
  }
  publishreport() {
    if (this.intro) {
      $('#myModal_survey').modal('hide');
      localStorage.setItem("surveyname", this.survey_name);
      this.router.navigate(['/surveydetailedreport', this.clientname, this.survey_number]);
    }
    else {
      Swal("Oops...", "Please setup default report text, Click report settings in top of the page", "error");
    }
  }
  getavg(data, i, item) {
    if (Array.isArray(data)) {
      var totalavg = data.map(function (item, index) { return parseFloat(item.ques_data) });
      var avg = math.mean(totalavg).toFixed(2);
      var stddev = math.std(totalavg).toFixed(2);
      if (totalavg.length > 1) {
        var variability = (stddev / avg) * 100;
        this.detailed_report[i]['variability'] = variability.toFixed(2);
      }
      return avg;
    }
    else {
      return data;
    }
  }
  getcount(type) {
    if (type == 'pending') {
      if (this.reportinfo && this.reportinfo.data && this.reportinfo.data.feedback_receiver_data) {
        return this.reportinfo.data.feedback_receiver_data.filter(name => (name.report_status == type)).length;
      }
      else {
        return 0;
      }
    }
    if (this.reportinfo && this.reportinfo.data && this.reportinfo.data.feedback_receiver_data) {
      return this.reportinfo.data.feedback_receiver_data.filter(name => (name.report_status == type || type == 'all')).length;
    }
    else {
      return 0;
    }
  }
  getUrl(user_profileImg) {
    if (user_profileImg) {
      return "url('profileimage/" + user_profileImg + "')";
    }
    else {
      return "url('profileimage/default.png')";
    }
  }
  percentage(completed, schedule) {
    return ((completed / schedule) * 100);
  }
  sendreminder(type,survey_number)
  {
    this.apiService.apirequest('sendreminder', { 'type':type,survey_number: survey_number,token: this.user.token,clientname: this.clientname }).subscribe(documents => {
      Swal("Success","Reminder has been sent successfully!","success");
    })
  }

}
