import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import swal from 'sweetalert2'
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
declare var $ :any;

@Component({
	selector: 'app-rolelist',
	templateUrl: './rolelist.component.html',
	styleUrls: ['./rolelist.component.css']
})
export class RolelistComponent implements OnInit {
	p:number = 1;itemsPerPage = 50;
    searchtext;searchWord
	constructor(private _dataService: ApiService, private router: Router) { }
	edit_id;
	privilege_menu = ['Clients', 'Survey', 'Competencies', 'Settings', 'Notifications' , 'SubAdmins'];
	addprivilege = ['Clients', 'Survey', 'Competencies', 'Settings', 'Notifications', 'SubAdmins'];
	editprivilege = ['Clients', 'Survey', 'Competencies', 'Settings', 'Notifications', 'SubAdmins'];
	deleteprivilege = ['Clients', 'Survey', 'Competencies', 'Settings', 'Notifications', 'SubAdmins'];
	rolelist=[];
	role_data = {};
	roleObje ={};
	eventact = "";
	form_url;
	user;
rolelistPermission
 	objRole = {
 		_id :"",
 		role_name:"",
 		role :{
 			'add': [],
 			'del': [],
 			'edit': [],
 			'view': []
 		},
 		user_id: '', 
		 token: '',
		 userlogin:'',
		  
 	}



	// xrole_name: any;
	roleext = false;loginuser
	ngOnInit() {
		  this.user = JSON.parse(localStorage.getItem("currentUser"));
		  this.loginuser=this.user.user.response.adminname
		this.objRole.user_id =this.user.user._id; 
 		this.objRole.token=this.user.token; 
		this.getRolelist();
		$("#role_form").validate({
			errorPlacement: function (error, element) {
			  return true;
			}
		  });
	}


// empty modal
emptyModal(){
	this.objRole = {
 		_id :"",
 		role_name:"",
 		role :{
 			'add': [],
 			'del': [],
 			'edit': [],
 			'view': []
 		} 
 		,
 		user_id: this.user.user._id, 
		 token: this.user.token,
		 userlogin: this.loginuser
 	};

	this.roleObje ={}
}


// rolelistdb
roledetailall
getRolelist(){
	this.roledetailall = {token: this.user.token }

	this._dataService.roleList(this.roledetailall).subscribe(data=>{
		this.rolelist=data.json();

 this.rolelistPermission = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
     
       if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].view.indexOf('settings');
       if(view == -1){
         this.router.navigate(['/accessdenied']);
       }
      }     
	})
}


add_new(data) {
	data.reset();
this.emptyModal();
this.roleObje ={};
       this.role_data = {};
       this.eventact = "add"
	   this.roleext = false;
	   
   };

   editrole(role,data) {
 
 	// data.form.value.xrole_name = role.role_name;
 	data.setValue({"xrole_name": role.role_name , "_id": role._id});
        var id = role._id;
        this.edit_id = role._id
       // this.role_data = {};
       // this.form_url = "/role/add";
       this.role_data = role;
       this.roleObje = role.role;
       // this.role_data.xrole_name = role.role_name
       this.eventact = "edit"
       this.roleext = false;
       this.objRole.role.view =[];
       this.objRole.role.add =[];
       this.objRole.role.edit =[];
       this.objRole.role.del =[];
	   this.objRole.userlogin= this.loginuser

       for(var i=0;i<this.roleObje[0].view.length; i++){

     			    this.objRole.role.view.push(this.roleObje[0].view[i].toLowerCase());
     		}

     		 for(var i=0;i<this.roleObje[0].add.length; i++){

     			    this.objRole.role.add.push(this.roleObje[0].add[i].toLowerCase());
     		}
     		 for(var i=0;i<this.roleObje[0].edit.length; i++){

     			    this.objRole.role.edit.push(this.roleObje[0].edit[i].toLowerCase());
     		}
     		 for(var i=0;i<this.roleObje[0].del.length; i++){

     			    this.objRole.role.del.push(this.roleObje[0].del[i].toLowerCase());
     		}
   };


   add_check(menu) {
	if (this.addprivilege.indexOf(menu) >= 0) {
		return true;
	}
}
edit_check(menu) {
	if (this.editprivilege.indexOf(menu) >= 0) {
		return true;
	}
}
delete_check(menu) {
	if (this.deleteprivilege.indexOf(menu) >= 0) {
		return true;
	}
}

check_role(role, type) {
     	var newobj = this.roleObje[0];
     
     	if   (this.roleObje[0] && type == "view") {
     		return (this.roleObje[0].view.indexOf(role.toLowerCase()) !== -1)
     	}
     	else if (this.roleObje[0] && type == "add") {
     		return (this.roleObje[0].add.indexOf(role.toLowerCase()) !== -1)
     	}
     	else if (this.roleObje[0] && type == "edit") {
     		return (this.roleObje[0].edit.indexOf(role.toLowerCase()) !== -1)
     	}
     	else if (this.roleObje[0] && type == "del") {
     		return (this.roleObje[0].del.indexOf(role.toLowerCase()) !== -1)
     	}
     	else {
     		return false
     	}
     }



     
     roledetails;
     roleexist(data) {
     	 data.form.value._id=this.edit_id;
     
     	
     	if (this.eventact == "add") {
     		this.roledetails = { role: data.form.value.xrole_name, action: this.eventact , user_id: this.user.user._id,token: this.user.token }
     	}
     	if (this.eventact == "edit") {
     		this.roledetails = { role: data.form.value.xrole_name, action: this.eventact, user_id: this.user.user._id,token:this.user.token }
     	}
     	if (this.roleObje[0] != "") {
     		this._dataService.roleExisted(this.roledetails).subscribe(response=>{
     		
     			if (response) {
     				if (response.json() == "exist") {
     					this.roleext = true;
     				
     				}
     				else {
     					this.roleext = false;
     				}
     			}
     			else {
     				this.roleext = false;
     			}
     		})
     	}
     }


// change
getCheckboxValue(data,permission,event){


     	if(permission == "view") {

     	  if(event.target.checked) {
     	    this.objRole.role.view.push(data.toLowerCase());
     		} 
     	    else { 

     	    	let i = this.objRole.role.view.indexOf(data.toLowerCase())
     	    	if (i > -1) {

               this.objRole.role.view.splice(i, 1)
				}
           }

     	
     	}
     	else if(permission == "edit"){
     		if(event.target.checked) {
     	    this.objRole.role.edit.push(data.toLowerCase());
     		} 
     	    else { 

     	    	let i = this.objRole.role.edit.indexOf(data.toLowerCase())
               this.objRole.role.edit.splice(i, 1)
           }
     	}
     	else if(permission =="add"){
     		if(event.target.checked) {
     	    this.objRole.role.add.push(data.toLowerCase());
     		} 
     	    else { 
     	    	let i = this.objRole.role.add.indexOf(data.toLowerCase())
               this.objRole.role.add.splice(i, 1)

           }
     	}
     	else if(permission == "del"){
     		if(event.target.checked) {
     	    this.objRole.role.del.push(data.toLowerCase());
     		} 
     	    else { let i = this.objRole.role.del.indexOf(data.toLowerCase())
               this.objRole.role.del.splice(i, 1)
           }
     	}
     	else{
     		return false;
     	}

}

// addNewRole
message;
addnewRole(data){
	if (!$("#role_form").valid()) {
		swal('Oops...',"Please submit all mantatory fields as required.",'error');
		$("#addModal").modal("show");
	  }else{

	if(data.form.value.xrole_name!=null && this.objRole.role != null){
	if (this.eventact == "add") {
	this.objRole.role_name = data.form.value.xrole_name;


this._dataService.addRoles(this.objRole).subscribe(result=>{

	this.message=result.json();
	if(this.message.status){
		swal("Good job!",this.message.message, "success");
		this.emptyModal();
		this.getRolelist();
		$('#model_popup').modal('hide');
	}else{
		swal("Oops!", this.message.message, "error");

	}
})

	}


if (this.eventact == "edit") {

	this.objRole.role_name = data.form.value.xrole_name;
	this.objRole._id = data.form.value._id;

	this._dataService.addRoles(this.objRole).subscribe(result=>{
	this.message=result.json();
	if(this.message.status){
		swal("Good job!",this.message.message, "success");
		this.emptyModal();
		this.getRolelist();
		$('#model_popup').modal('hide');
	}else{
		swal("Oops!", this.message.message, "error");

	}
})
	}

}else{
	swal("Oops!","All Fields are Required","error");
}
}
}

response;
  delete(del_id){

	swal({
		title: 'Are you sure?',
		text: 'You will not be able to recover this data!',
		type: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes, delete it!',
		cancelButtonText: 'No, keep it'
	  }).then((result) => {
		if (result.value) {
		  this._dataService.getRoleByid({ token: this.user.token,userlogin:this.loginuser,del_id: del_id }).subscribe(data => {
			this.response = data.json();
			this.getRolelist();
			if (this.response.status) {
			  swal('Deleted!', this.response.message, 'success');
			}
			else {
			  swal('Oops...', this.response.message, 'error');
			}
		  })
		}
	  })
 // this.toastr.success('Workflow has been deleted successfully.', null, { toastLife: 10000 });

  }

  checkAddPermission(add){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkEditPermission(edit){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].edit.indexOf(edit);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkDelPermission(del){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].del.indexOf(del);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

 }
