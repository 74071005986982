import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';

import { Router, ActivatedRoute } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-dymailer',
  templateUrl: './dymailer.component.html',
  styleUrls: ['./dymailer.component.css']
})
export class DymailerComponent implements OnInit {

  mailerlist: any = [];
  mailer_id: any;
  selected_mailer: any = '';
  mailer_details: any = [];
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log: string = '';
  clientname = '';
  survey_id = '';
  @ViewChild("myckeditor") ckeditor: any;


  quillConfig = {
    toolbar: {
      container: [["bold", "italic", "underline", "strike"], // toggled buttons
      [{ header: 1 }, { header: 2 }], [{ list: "ordered" }, { list: "bullet" }], [{ size: ["small", false, "large", "huge"] }],
      [{ align: [] }], [{ 'color': [] }], [{ 'font': [] }]]
    }
  };

  constructor(private api: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.clientname = params['client_name'];
      this.survey_id = params['survey_id'];
    });
    this.getmailernames();

    this.mailerlist = ["Client Registration Mail", "Client Survey Mail"];
    this.ckeConfig = {
      // extraPlugins: 'MediaEmbed',
      toolbar: [
        { name: "document", items: ["Source"] }, // Defines toolbar group with name (used to create voice label) and items in 3 subgroups.
        ["Cut", "Copy", "PasteText", "-", "Undo", "Redo"], // Defines toolbar group without name.
        "/", // Line break - next group will be placed in new line.
        {
          name: "basicstyles",
          items: ["Bold", "Italic", "Underline", "Strike"]
        },
        { name: "insert", items: ["HorizontalRule", "Link", "imageExplorer"] },
        { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] }
      ],
      table: {
        customClass: ["ui", "table", "celled"], // Important!!! need to be array
      },
      extraAllowedContent:
        "strong[click];iframe[*];img;section;span;ul;li;table;td;th;style;*[id];*(*);*{*}",
      allowedContent: true,
      height:550
    };
  }

  activate_list(event, selected, ind) {

    $('.newsale_box').find('.active_comp').removeClass("active_comp");
    $(event.target).addClass('active_comp');
    this.selected_mailer = selected;

  }
  getmailernames() {
    let data = {"token": JSON.parse(localStorage.getItem("currentUser")).token,clientname:'',survey_id:'' }
    if(this.clientname && this.clientname.length>0)
    {
    data = { "token": JSON.parse(localStorage.getItem("currentUser")).token, "clientname" : this.clientname,survey_id:this.survey_id }
    }
    this.api.getmailernames(data).subscribe((res: any) => {
      this.mailer_details = res.json().data;
    })
  }
  get_content(id) {
    this.mailer_id = id;
    let data = {token: JSON.parse(localStorage.getItem("currentUser")).token,mailer_id: id,"clientname" : this.clientname,survey_id:this.survey_id}
    this.api.getmailercontent(data).subscribe((res: any) => {
      this.mycontent = res.json().data.content;
    })
  }
  update_content() {
    let update_details = {
      id: this.mailer_id,
      content: this.mycontent,
      token: JSON.parse(localStorage.getItem("currentUser")).token,"clientname" : this.clientname
    }
    this.api.updatemailer_content(update_details).subscribe(res => {
      Swal('Success', 'Content updated successfully ', 'success');
    })
  }

}
