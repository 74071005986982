import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { ApiService } from "../api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import * as math from "mathjs";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
declare var Highcharts: any;

declare var $: any;

@Component({
  selector: "app-surveydetailedreport",
  templateUrl: "./surveydetailedreport.component.html",
  styleUrls: ["./surveydetailedreport.component.css"],
})
export class SurveydetailedreportComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private location: Location,
    private _sanitizer: DomSanitizer
  ) {}
  user;
  consolidated_reports = [{status:'', content:'', title:''}];
  roles: any = [];
  is_survey_expired:boolean
  get_giver_details: any;
  survey_questions:any =[];
  survey_start = new Date(); survey_end = new Date(); report_status = 'pending';
  show_reports: any = [];
  clientname;
  consolidated_feedback:any;
  survey_id;
  ques_ans_about_survey: any = [];
  devwindow = [];
  reportinfo = {
    data: {
      ques_ans_about_survey: [],
      introstatus: false,
      profilechartStatus: false,
      parameterwiseStatus: false,
      topStrengths: false,
      windowStatus: false,
      closureStatus: false,
      reports: {},
      startdate:new Date(),
      enddate:new Date(),
      report_status: "",
      survey_rating: {
        ratingscale_point: 0,
      },
      survey_name: "",
      feedback_receiver_data: {
        receiverinfo: {
          user_name: "",
          user_department: "",
          user_profileImg: "",
        },
      },
    },
    clientinfo: {
      client_company_name: "",
      company_logo: "",
    },
  };
  detailed_report = [];
  responseData;
  survey_number: String;
  survey_name: String;
  rolearr = [];
  rolearrvalue = [];
  pointarr = [];
  reports;
  loadingmsg: String = "";
  response;
  reportstatus = "N";
  colorsdata = {
    selfcolor: "#87c451",
    management: "#438dd2",
    other: "#ae0e5a",
    window_1_color: "#fe8cb3",
    window_2_color: "#7fff9b",
    window_3_color: "#ffd8a5",
    window_4_color: "#c0ff7f",
  };
  @ViewChild("downloadreport") downloadreport: ElementRef;
  downloaderurl = "";
  competencyrowlist = [];
  chartdata = {};
  quillConfig = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            header: 1,
          },
          {
            header: 2,
          },
        ], // custom button values
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
        [
          {
            size: ["small", false, "large", "huge"],
          },
        ], // custom dropdown
        [
          {
            align: [],
          },
        ],
        [{ color: [] }],
        [{ font: [] }],
      ],
    },
  };

  MAX_LENGTH = 800;
  currentlength = 0;
  textChanged($event) {
    if ($event.editor.getLength()) {
      this.currentlength = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH) {
      $event.editor.deleteText(this.MAX_LENGTH, $event.editor.getLength());
    }
  }
  textansrChanged($event, length) {
    if ($event.editor.getLength()) {
      this.currentlength = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > length) {
      $event.editor.deleteText(length, $event.editor.getLength());
    }
  }
  MAX_LENGTH2 = 800;
  currentlength2 = 0;
  textChangedImprovement($event) {
    if ($event.editor.getLength()) {
      this.currentlength2 = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH2) {
      $event.editor.deleteText(this.MAX_LENGTH2, $event.editor.getLength());
    }
  }
  MAX_LENGTH3 = 1400;
  currentlength3 = 0;
  textChangedIntroduction($event) {
    if ($event.editor.getLength()) {
      this.currentlength3 = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH3) {
      $event.editor.deleteText(this.MAX_LENGTH3, $event.editor.getLength());
    }
  } 

  textChangedFeedback($event) {

  }

  MAX_LENGTH4 = 1000;
  currentlength4 = 0;
  textChanged360Window($event) {
    if ($event.editor.getLength()) {
      this.currentlength4 = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH4) {
      $event.editor.deleteText(this.MAX_LENGTH4, $event.editor.getLength());
    }
  }

  MAX_LENGTH5 = 1000;
  currentlength5 = 0;
  textChangedPrameterwise($event) {
    if ($event.editor.getLength()) {
      this.currentlength5 = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH5) {
      $event.editor.deleteText(this.MAX_LENGTH5, $event.editor.getLength());
    }
  }

  MAX_LENGTH6 = 600;
  currentlength6 = 0;
  textChangedCompentencyChart($event) {
    if ($event.editor.getLength()) {
      this.currentlength6 = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH6) {
      $event.editor.deleteText(this.MAX_LENGTH6, $event.editor.getLength());
    }
  }
  MAX_LENGTH7 = 1000;
  currentlength7 = 0;
  textChangedCompentency($event) {
    if ($event.editor.getLength()) {
      this.currentlength7 = $event.editor.getLength() - 1;
    }
    if ($event.editor.getLength() > this.MAX_LENGTH7) {
      $event.editor.deleteText(this.MAX_LENGTH7, $event.editor.getLength());
    }
  }
  dev_avg = 0;
  ngOnInit() {
    this.ngxService.start();

    if (localStorage.getItem("currentUser")) {
      this.user = JSON.parse(localStorage.getItem("currentUser"));
    }

    this.survey_name = localStorage.getItem("surveyname");
    this.route.params.subscribe((params) => {
      this.clientname = params["clientid"];
      this.survey_number = params["surveynumber"];
    });

    this.reports = {
      introduction: "",
      profile_desc: "",
      paramater_wise: "",
      competency: {},
      dev_window: "",
      closure: "",
      strengths: "",
      improvement: "",
      response_summary:
        "The following groups of individuals were invited to participate by providing their feedback so as to get a complete 360° view",
      rating_summary:
        "The rating scale shown below was used in scoring quantitative feedback",
    };
    this.getroles();

    this.apiService
      .apirequest("getclientanduserdata", {
        token: this.user.token,
        survey_number: this.survey_number,
        clientname: this.clientname,
        type: "surveydetail",
      })
      .subscribe((data) => {
        this.reportinfo = data.json();
        console.log(this.reportinfo);
        // this.reportinfo.dat
       
        if (this.reportinfo.data.reports) {
          
          this.reports = this.reportinfo.data.reports;
          if(this.reports.introduction) {
            
            this.reports.introduction = this.reports.introduction;

          } else {
            
            this.reports.introduction = this.reportinfo.data["introduction"];
          }
          if(this.reports.profile_desc) {
            console.log('dat1')
            this.reports.profile_desc = this.reports.profile_desc;

          } else {
            console.log('dat2',this.reportinfo.data["profile_desc"])
            this.reports.profile_desc = this.reportinfo.data["competencyProfileChart"];
            console.log('dat2',this.reports)
          }
          if(this.reports.paramater_wise) {
            
            this.reports.paramater_wise = this.reports.paramater_wise;

          } else {
            
            this.reports.paramater_wise = this.reportinfo.data["parameterWiseReport"];
          }
          if(this.reports.dev_window) {
            
            this.reports.dev_window = this.reports.dev_window;

          } else {
            
            this.reports.dev_window = this.reportinfo.data["developmentwindow"];
          }
          this.reports.closure = this.reportinfo.data["closureText"];
        } else if (!this.reportinfo.data.reports) {
          
          this.reports.introduction = this.reportinfo.data["introduction"]
          this.reports.profile_desc = this.reportinfo.data[
            "competencyProfileChart"
          ];
          this.reports.paramater_wise = this.reportinfo.data[
            "parameterWiseReport"
          ];
          this.reports.dev_window = this.reportinfo.data["developmentwindow"];
          this.reports.closure = this.reportinfo.data["closureText"];
        }
        
       
        // console.log('role_name', role_name);
        let quest_details: any = [];
        let feedback_details: any = this.reportinfo.data;
        if(feedback_details &&feedback_details.consolidated_feedback && feedback_details.consolidated_feedback.length>0) {
        this.consolidated_reports = feedback_details.consolidated_feedback
        }
        this.survey_questions= feedback_details.questions_about_survey
        this.get_giver_details = feedback_details.feedback_giver_id;
        let report_data: any = this.reportinfo.data;

        this.ques_ans_about_survey = this.reportinfo.data.ques_ans_about_survey;
        // this.ques_ans_about_survey.map((val, index) => {
        //   val.updated_answers = val.updated_answers
        //     ? val.updated_answers
        //     : val.answer;
        //   this.get_giver_details.forEach((ele) => {
        //     console.log(ele.ques_ans_about_survey[val.question.question].updated_answers)
            
        //     ele.ques_ans_about_survey[index].updated_answers = ele
        //       .ques_ans_about_survey[index].updated_answers 
        //       ? ele.ques_ans_about_survey[index].updated_answers
        //       : ele.ques_ans_about_survey[index].answer;
            
        //   });
        // });

        this.pointarr = Array(
          this.reportinfo.data.survey_rating.ratingscale_point
        )
          .fill(this.reportinfo.data.survey_rating.ratingscale_point)
          .map((x, i) => i);

        this.dev_avg = parseFloat(((this.pointarr.length + 1) / 2).toFixed(2));
        

        
        
        if (
          this.reportinfo.data.report_status &&
          this.reportinfo.data.report_status == "published"
        ) {
          this.reportstatus = "Y";
        }
        if (this.reportinfo.data["color"]) {
          this.colorsdata = this.reportinfo.data["color"];
        }
        if (this.reportinfo.data["rating_summary"]) {
          this.reports.response_summary = this.reportinfo.data[
            "response_summary"
          ];
          this.reports.rating_summary = this.reportinfo.data["rating_summary"];
        }
        this.survey_id = this.reportinfo.data["_id"];
      });
    this.apiService
      .apirequest("getsurveyreports", {
        token: this.user.token,
        survey_number: this.survey_number,
        survey_name: this.survey_name,
        clientname: this.clientname,
        type: "surveydetail",
      })
      .subscribe((data) => {
        var response = data.json();
        this.detailed_report = data.json().data;
        this.ngxService.stop();
        if (
          this.detailed_report &&
          this.detailed_report[0] &&
          this.detailed_report[0].avg_all
        ) {
          this.rolearr = Object.keys(this.detailed_report[0].avg_all);
          this.rolearrvalue = Object.values(this.detailed_report[0].avg_all);
          this.competencyrowlist = this.detailed_report.map(function (
            element,
            index
          ) {
            return element.competency_name;
          });
          this.loadchart();
        }
        if (response && response["areaofimprove"]) {
          this.reports.strengths =
            "<ul>" +
            response["topfivestrength"]
              .map((item) => `<li>${item}</li>`)
              .join(" ") +
            "</ul>";
          this.reports.improvement =
            "<ul>" +
            response["areaofimprove"]
              .map((item) => `<li>${item}</li>`)
              .join(" ") +
            "</ul>";
        }
      });
  }
  getroles() {
    this.apiService
      .apirequest("getroles", {
        token: this.user.token,
        clientname: this.clientname,
        survey_number: this.survey_number,
      })
      .subscribe((data) => {
        this.roles = data.json();
      });
  }
  getrolename(role_id) {
    let name = this.roles.data.filter((val) => val._id == role_id)[0].role_name;

    return name;
  }
  saveAnswers() {
    this.ngxService.start();
    // this.apiService
    //   .apirequest("savesurveyAnswers", {
    //     token: this.user.token,
    //     survey_number: this.survey_number,
    //     survey_name: this.survey_name,
    //     clientname: this.clientname,
    //     ques_ans_about_survey: this.ques_ans_about_survey,
    //     feedback_receiver_data: this.reportinfo.data.feedback_receiver_data,
    //     feedback_giver: this.get_giver_details,
    //   })
    //   .subscribe((data) => {
    //     if (data.json()) {
    //       this.ngxService.stop();
    //     }
    //   });
    let filter_data = this.consolidated_reports.filter(val=>val.title && val.status);
    
    this.apiService.apirequest('saveReport', {
      token:this.user.token,
      clientname: this.clientname,
      survey_number: this.survey_number,
      report_data:filter_data
    }).subscribe((data)=>{
      
      this.ngxService.stop();
    })
  }
  getcolors(type) {
    if (type == 1) {
      return this._sanitizer.bypassSecurityTrustStyle(
        "linear-gradient(to top," +
          this.colorsdata.window_1_color +
          " 21%, " +
          this.colorsdata.window_1_color +
          " 72%)"
      );
    }
    if (type == 2) {
      return this._sanitizer.bypassSecurityTrustStyle(
        "linear-gradient(to top," +
          this.colorsdata.window_2_color +
          " 21%, " +
          this.colorsdata.window_2_color +
          " 72%)"
      );
    }
    if (type == 3) {
      return this._sanitizer.bypassSecurityTrustStyle(
        "linear-gradient(to top," +
          this.colorsdata.window_3_color +
          " 21%, " +
          this.colorsdata.window_3_color +
          " 72%)"
      );
    }
    if (type == 4) {
      return this._sanitizer.bypassSecurityTrustStyle(
        "linear-gradient(to top," +
          this.colorsdata.window_4_color +
          " 21%, " +
          this.colorsdata.window_4_color +
          " 72%)"
      );
    }
  }
  loadchart() {
    var colors = ["#438dd2", "#ae0e5a", "#75bb37"];

    var competencyrow = [];
    var others_arr = {};
    var self_arr = [];
    var seriesrow = [];
    this.detailed_report.map(function (element, index) {
      competencyrow.push(element.competency_name);
      var role = Object.keys(element.avg);
      role.map(function (element1, index1) {
        if (!others_arr[element1] && element1 != "self") {
          others_arr[element1] = [];
        }
        if (element1 == "self") {
          self_arr.push(parseFloat(element.avg[element1]));
        } else {
          others_arr[element1].push(parseFloat(element.avg[element1]));
        }
      });
    });
    seriesrow.push({
      name: "Self Score",
      data: self_arr,
      color: this.colorsdata.selfcolor,
      type: "spline",
    });
    colors[0] = this.colorsdata.management;
    colors[1] = this.colorsdata.other;
    var rolearray = Object.keys(others_arr);
    rolearray.forEach(function (element1, index1) {
      const textcaptial = element1.charAt(0).toUpperCase() + element1.slice(1);

      seriesrow.push({
        name: textcaptial + " Score",
        data: others_arr[element1],
        color: colors[index1],
        type: "column",
      });
    });

    this.chartdata = {
      chart: {
        zoomType: "xy",
        width: 700,
        height: 400,
      },
      title: false,
      subtitle: false,
      xAxis: {
        categories: competencyrow,
        crosshair: true,
      },
      yAxis: {
        min: 0.0,
        max: 5.0,
        title: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.2f}",
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      series: seriesrow,
    };

    Highcharts.chart("profilechart", this.chartdata);
  }
  getUrl(user_profileImg) {
    if (user_profileImg) {
      return "url('profileimage/" + user_profileImg + "')";
    } else {
      return "url('profileimage/default.png')";
    }
  }
  reporttype = "intro";
  reportview(reporttype) {
    if (reporttype == "profilechart") {
      this.loadchart();
    }
    if (reporttype == "dev_window") {
      this.checkgraphposition();
    }
    this.reporttype = reporttype;
  }
  downloadreports() {
    let api_value;
    this.survey_start = new Date(this.reportinfo.data.startdate);
    this.survey_end = new Date(this.reportinfo.data.enddate);
    var now = new Date();
  now.setHours(0,0,0,0);
  if (this.survey_end < now) {
   this.is_survey_expired = true
  } else {
   this.is_survey_expired= false
  }
  // console.log('.....',this.reportinfo.data.report_status =='completed' , this.is_survey_expired);
    if(this.reportinfo.data.report_status =='completed' ) {
      api_value = 'getsurveyreports'
    } else {api_value = 'getsurveyreports_expired_reports'}
    this.ngxService.start();
    this.apiService
      .apirequest(api_value, {
        token: this.user.token,
        survey_number: this.survey_number,
        survey_name: this.survey_name,
        clientname: this.clientname,
        type: "downloadreport",
        chartdata: this.chartdata,
        detailed_report: this.detailed_report,
      })
      .subscribe((data) => {
        if (data.json().status) {
          this.downloaderurl = data.json().data;
          setTimeout(() => {
            let el: HTMLElement = this.downloadreport
              .nativeElement as HTMLElement;
            el.click();
            this.ngxService.stop();
          }, 100);
        } else {
          Swal("Oops...", "Error! Try Again", "error");
        }
      });
  }

  savereport() {
    this.loadingmsg = "Please Wait .........";
    this.apiService
      .apirequest("updatereport", {
        token: this.user.token,
        survey_number: this.survey_number,
        clientname: this.clientname,
        reports: this.reports,
        type: "savereport",
      })
      .subscribe((data) => {
        setTimeout(() => {
          this.loadingmsg = "Saved Successfully";
        }, 700);
        setTimeout(() => {
          this.loadingmsg = "";
        }, 2000);
      });
  }
  savecolors() {
    this.apiService
      .apirequest("savecolors", {
        clientname: this.clientname,
        token: this.user.token,
        color: this.colorsdata,
        survey_id: this.survey_id,
      })
      .subscribe((data) => {});
  }
  checkgraphposition() {
    this.devwindow = [[], [], [], []];
    this.detailed_report.map((x) => {
      let mgmt =
        x.variability && x.variability.management
          ? parseFloat(x.variability.management)
          : 0;
      let other =
        x.variability && x.variability.other
          ? parseFloat(x.variability.other)
          : 0;

      if (x.avg.other > this.dev_avg && x.avg.management <= this.dev_avg) {
        this.devwindow[0].push(x.competency_name);
      }
      if (x.avg.other > this.dev_avg && x.avg.management > this.dev_avg) {
        if (mgmt <= 20 && other <= 20) {
          this.devwindow[1].push(x.competency_name);
        }
        if ((mgmt > 20 && other <= 20) || (mgmt <= 20 && other > 20)) {
          this.devwindow[3].push(x.competency_name);
        }
        if (mgmt > 20 && other > 20) {
          this.devwindow[0].push(x.competency_name);
        }
      }
      if (
        x.avg.management <= this.dev_avg &&
        (x.avg.other <= this.dev_avg || x.avg.other == undefined)
      ) {
        this.devwindow[2].push(x.competency_name);
      }
      if (
        x.avg.management > this.dev_avg &&
        (x.avg.other <= this.dev_avg || x.avg.other == undefined)
      ) {
        if (mgmt <= 20 && other <= 20) {
          this.devwindow[3].push(x.competency_name);
        }
        if ((mgmt > 20 && other <= 20) || (mgmt <= 20 && other > 20)) {
          this.devwindow[0].push(x.competency_name);
        }
        if (mgmt > 20 && other > 20) {
          this.devwindow[2].push(x.competency_name);
        }
      }
    });
  }
  viewreport(type) {
    this.apiService
      .apirequest("viewreport", {
        clientname: this.clientname,
        token: this.user.token,
        type: type,
        survey_number: this.survey_number,
      })
      .subscribe((data) => {
        var response = data.json();

        if (response.success) {
          //localStorage.setItem("surveyname" ,this.survey_name);
          this.router.navigate([
            "/surveydetailedreport",
            this.clientname,
            response.data.survey_number,
          ]);
          setTimeout(() => {
            location.reload();
          }, 100);
        } else {
          Swal("Sorry!", "No Reports Available", "error");
        }
      });
  }
  opennewEditor() {
    this.consolidated_reports.push({status:'', content:'', title:''});
  }
  closeeditor(n) {
    this.consolidated_reports.splice(n,1)
  }

  publishreports() {
    Swal({
      title: "Are you sure?",
      text: "You will not be able to unpublish the reported data!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Publish it!",
      cancelButtonText: "No, Cancel it",
    }).then((result) => {
      if (result.value) {
        this.apiService
          .apirequest("updatereport", {
            token: this.user.token,
            survey_number: this.survey_number,
            clientname: this.clientname,
            reports: this.reports,
            type: "publishreports",
          })
          .subscribe((data) => {
            this.response = data.json();
            if (this.response.success) {
              this.reportstatus = "Y";
              Swal(
                "Success",
                "Report has been published successfully!",
                "success"
              );
            } else {
              Swal("Oops...", "Error! Try Again", "error");
            }
          });
      }
    });
  }
}
