import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {
  surveyname;surveydes;competency;user;data_id;survey_status;response;showCompetency;competencyAllList;
  surveyList=[];compentencyID=[];survey_rating
  constructor(private apiService: ApiService,private router: Router,private route: ActivatedRoute) { }
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};getstatus ='';
  dropdownListArray;
  p:number = 1;itemsPerPage = 50;

  searchWord;showRating;loginuser;
  rolelistPermission;
  rolelist;
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.loginuser=this.user.user.response.adminname
    $("#survey_form").validate({
			errorPlacement: function (error, element) {
			  return true;
			}
      });
      this.dropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'competency_name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 5,
        allowSearchFilter: true
      };
      this.competencyList();
      this.showsurvey();
      this.ratingList();
      this.getRoles();
    }

    getRoles() {
    this.apiService.roleList({ user_id: this.user.user._id, token: this.user.token }).subscribe(data => {
      this.rolelist = data.json();
      this.rolelistPermission = data.json().filter( x=> {return x._id == this.user.user.response.roletype} );
       if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].view.indexOf('survey');
       if(view == -1){
         this.router.navigate(['/accessdenied']);
       }
      }     
    })
  }

    competencyList(){
      this.apiService.dispAllCompetencyList({token:this.user.token}).subscribe(data=>{
        this.dropdownList= data.json();
        });
      }

      ratingList(){
        this.apiService.dispAllRatingList({token:this.user.token}).subscribe(data=>{
          this.showRating = data.json();
        })
      }

    showsurvey(){
      this.apiService.dispAllSurveyList({token:this.user.token}).subscribe(data=>{
        this.surveyList= data.json();
        });
    }

    onItemSelect(item){
      $(".multiselect-dropdown").css("border","none");
    }

      onSelectAll(items){
        $(".multiselect-dropdown").css("border","none");
      }
    
    addsurvey(){
      this.data_id="";
      this.surveyname="";
      this.surveydes="";
      this.selectedItems=[];
      this.survey_status="";
      this.survey_rating="";
      
    }

  schedulSurvey(){
    if (!$("#survey_form").valid()) {
      $(".multiselect-dropdown").css("border","1px solid red");
      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
      $("#survey_popup").modal("show");
      }else{
      this.apiService.schedulSurvey({ token: this.user.token,userlogin:this.loginuser, data_id: this.data_id, survey_name: this.surveyname, survey_des: this.surveydes, competency: this.selectedItems,survey_rating:this.survey_rating, status: this.survey_status }).subscribe(data => {
        this.response = data.json();
        $("#survey_popup").modal("hide");
        if (this.response.status) {
          Swal('Success', this.response.message, 'success');
        }
        else {
          Swal('Oops...', this.response.message, 'error')
        }
         this.showsurvey();
      })

 }
  }
  
  edit(data) {

    this.router.navigate(['/surveydetails/'+ data._id ]);

  }
  delete(del_id){
    Swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteSurvey({ token: this.user.token, userlogin:this.loginuser,del_id: del_id }).subscribe(data => {
          this.response = data.json();
          this.showsurvey();
          if (this.response.status) {
            Swal('Deleted!', this.response.message, 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error');
          }
        })
      }
    })
  }

  checkAddPermission(add){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].add.indexOf(add);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkEditPermission(edit){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].edit.indexOf(edit);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}

checkDelPermission(del){
      if(this.rolelistPermission != undefined){
       var view = this.rolelistPermission[0].role[0].del.indexOf(del);
       if(view == -1){
        return false;
       }
       else{
        return true;
       }
      }     
}
}
