import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from "rxjs";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  result;
  user;
  public token: string;

  constructor(private _http: Http) { }

  userLogin(user) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post('/login', JSON.stringify(user), { headers: headers })
      .map(res => {
        let status = res.json();
      
        if (status[0].success == true) {
          this.token = status[0].token;
          this.user = status[0].data;
          var expirationMS = 350 * 60 * 1000;
        
          var record = { value: JSON.stringify('expiry'), timestamp: new Date().getTime() + expirationMS }
          localStorage.setItem('expire_check', JSON.stringify(record));
          localStorage.setItem('profile_pic', this.user.response.profile_pic);
          localStorage.setItem("currentUser", JSON.stringify(
            {
              user:this.user,
              token: this.token
            }));
          return res.json();
        }
        else {


          return res.json();
        }
      })

  }
  adminlogout() {
    this.token = null;
    localStorage.removeItem("currentUser");
  }

  dispAllClientAdminList(data) {
    return this._http.post("/getAllClientAdminList", data).map(res => res);
  }
  dispAllCompetencyList(data) {
    return this._http.post("/getAllCompetencyList", data).map(res => res);
  }
  dispAllSurveyList(data) {
    return this._http.post("/getAllSchedulSurvey", data).map(res => res);
  }
  dispAllRatingList(data) {
    return this._http.post("/getAllRatingList", data).map(res => res);
  }
   addClientAdmin(clientdata) {
    var surveyarr:any=[];
    const formData: FormData = new FormData();
    if(clientdata.data.clientLogo!=undefined){
    // formData.append('employeeImage', clientdata.data.clientImage, clientdata.data.clientImage.name);
    formData.append('employeeLogo', clientdata.data.clientLogo, clientdata.data.clientLogo.name);
    }
      formData.append('Client_company_name',clientdata.data.Client_company_name);
      formData.append('Client_name',clientdata.data.Client_name);
      formData.append('token',clientdata.token);
      formData.append('userlogin',clientdata.userlogin);
      formData.append('Client_domainName',clientdata.data.Client_domainName);
      // formData.append('RatingScale_id',clientdata.data.RatingScale_id);
      clientdata.data.choose_survey.forEach(element => {
        surveyarr.push(element)
      });
   
      formData.append('Competency_id',JSON.stringify(surveyarr));
      formData.append('Competency_id_len',surveyarr.length);
      formData.append('Client_email',clientdata.data.Client_email);
      formData.append('Client_address',clientdata.data.Client_address);
      formData.append('Client_Status',clientdata.data.Client_Status);
      if(clientdata.data.clientLogo!=undefined){
      // formData.append('Client_profileImg',clientdata.data.clientImage.name);
      formData.append('Client_logo',clientdata.data.clientLogo.name);
      }
      formData.append('data_id',clientdata.dataid);
  
   return this._http.post("/addClientAdmin", formData).map(res => res);
  }
  delete(id)
{
  return  this._http.post("/getClientByid", id).map(res => res);
}
  addRatingScaleApi(data)
  {
    return this._http.post("/addRatingScaleApi", data).map(res => res);
  }
  listAllRatingScales(data)
  {
    return this._http.post("/listAllRatingScales", data).map(res => res);
  }
  deleteRatingScale(data)
  {
    return this._http.post("/deleteRatingScale", data).map(res => res);
  }

  apirequest(url,data)
  {
    return this._http.post(url, data).map(res => res);  
  }

  roleList(roledetailall) {
    return this._http.post("/rolelistdb",roledetailall).map(res => res);
  }
  roleExisted(roledetails){
    return this._http.post("/roleexistd",roledetails).map(res => res);
  }
  addRoles(object){
    return this._http.post("/role/add",object).map(res => res);
  }
  getRoleByid(id)
  {
    return  this._http.post("/getRoleByid", id).map(res => res);
  }
  

  addSubadmins(data)
  {
    return this._http.post("/addsubadmins", data).map(res => res);
  }

  
  subadminList(roledetailall) {
        return this._http.post("/subadminlistdb",roledetailall).map(res => res);
      }
      


      deleteSubadmin(data)
      {
        return this._http.post("/deleteSubadmin", data).map(res => res);
      }
      passwordReset(data)
      {
        return this._http.post("/passwordReset", data).map(res => res);
      }

      schedulSurvey(data){
        return this._http.post("/schedulSurvey", data).map(res => res);
      }
      deleteSurvey(data)
      {
        return this._http.post("/deleteSurvey", data).map(res => res);
      }
     
      getSurveydetails(data){
        return this._http.post("/getSurveydetails", data).map(res => res);
      }
      

      deleteCompetency(data)
      {
        return this._http.post("/deleteCompetency", data).map(res => res);
      }

      deleteQuestions(data)
      {
        return this._http.post("/deleteQuestions", data).map(res => res);
      }
      forgotLoginPass(emailobj){
        return this._http.post("/forgotPassLogin", emailobj).map(res => res);
      }
      
      notificationList(notification) {
        return this._http.post("/notificationList",notification).map(res => res);
      }

      getmailernames(data)
      {
        return this._http.post("/get_mailer_names",data).map(res => res);
      }

      getmailercontent(data){
        return this._http.post("/get_mailer_content",data).map(res => res);
      }
      updatemailer_content(data) {
        return this._http.post("/update_mailer_content",data).map(res => res);
      }
      
}

