import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { filterQueryId } from '@angular/core/src/view/util';

declare var $: any;

@Component({
  selector: 'app-surveydetails',
  templateUrl: './surveydetails.component.html',
  styleUrls: ['./surveydetails.component.css']
})
export class SurveydetailsComponent implements OnInit {
  user; showRating; competencylist; selected_comp; data_id; questionlist; questionid; response; loginuser; surveyname; surveydes; selectedItems; survey_rating
  questionind = []; selectedArr = []; dataid = ''; edituserid; surveydetails; competencydetails; getstatus;searchtext;
  setindex; comcheckval; getcompid = []; finalarr; getcom = []; urltext; temparr = []; checkingvalues = []; checkedvalues = []
  comselected = []; geturl; checkidcom;
  pointarr = [0] ; questioninfo:any=[{question:'',required:false,input_length:1}];
  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.checkidcom = "unchecked"
    this.geturl = "activate_competency($event,i,comp_row)";
    this.user = JSON.parse(localStorage.getItem("currentUser"));
    this.loginuser = this.user.user.response.adminname
    this.route.params.subscribe(params => { this.edituserid = params['id']; });
    if (this.edituserid) {
      this.getSurveyToDisplay(this.edituserid);
    }
    this.ratingList();
    this.competencyList();
    this.questionind = [];

    $("#survey_form").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
  }


addmorequestion()
  {
    this.pointarr.push(this.pointarr.length);
 let data ={question:'',required:false,input_length:0}
 this.questioninfo.push(data)

  }
  removequestion(index)
  {
    this.pointarr.splice(index, 1);
    this.questioninfo.splice(index, 1);

  }
 // clearques(){
 //    // this.questionind= null;
 //    this.questioninfo=[];
 //    this.pointarr = [0];
 //  }


  submitquestion() {

    if (!$("#feedback_from").valid()) {
      $("#feedback_from").disabled=true;

      Swal('Oops...',"Please submit all mantatory fields as required.",'error');
    }else{
      $("#feedbackquestion").modal('hide');
      }
}
opennewquestion(){
    this.pointarr = [0];
    for(var i = 0 ; i<this.surveydetails.data[0].questions_about_survey.length-1; i++){

     this.pointarr.push(i);
    }
}

  compylist; checkingcomp
  getSurveyToDisplay(id: number) {
    this.apiService.getSurveydetails({ token: this.user.token, dataid: id, }).subscribe(
      data => {
        this.surveydetails = data.json();
        // this.pointarr.push(this.surveydetails.data[0].questions_about_survey.length);
        console.log('survey ', this.questioninfo);
        this.questioninfo =  this.surveydetails.data[0].questions_about_survey;
        // this.surveydetails.data[0].questions_about_survey.forEach(val=>{
        //   this.questioninfo.push(val)
        // })
        console.log('survey 1', this.questioninfo);
        console.log('question infp ', this.questioninfo);
        this.dataid = this.surveydetails.data[0]._id;
        this.surveyname = this.surveydetails.data[0].survey_name;
        this.surveydes = this.surveydetails.data[0].survey_desc;
        this.survey_rating = this.surveydetails.data[0].survey_rating;
        this.getstatus = this.surveydetails.data[0].survey_status;
        this.competencydetails = this.surveydetails.data[0].competency;
        this.getcom = [];
        this.urltext = "Edit";
        this.geturl = "checkboxchecked()";

        this.getcom = this.competencydetails
        var count = 0;
        this.getcom.forEach(element => {

          this.getcompid.push(element.competency_id)
          if (count == 0) {
            this.selected_comp = true;
            this.data_id = element.competency_id;
            this.getquestionlist();
            var queind

            if (this.competencydetails) {
              this.getcom = this.competencydetails

              this.selectedItems = this.competencydetails.filter(function (competencies) {

                if (element.competency_id.indexOf(competencies.competency_id) > -1) {
                  queind = competencies.question_index;
                } else {
                  return false;
                }

              });
              this.setindex = queind

              element.question_index.forEach(elements => {
                return (this.setindex.indexOf(elements) !== -1)

              });
            }
            this.apiService.apirequest('/getCompdetails', { token: this.user.token, data_id: element.competency_id }).subscribe(data => {
              if (data.json().status) {
                this.compylist = data.json().data;
                this.compylist.forEach(element => {
                  $("#bind_compname").html(element.competency_name)
                  $("#bind_compdec").html(element.competency_desc)
                });
              }
            })
            count++;
          }

        });
        $('.newsale_product_section').addClass('newsale_product_section editclass');
      });
  }
  ratingList() {
    this.apiService.dispAllRatingList({ token: this.user.token }).subscribe(data => {
      this.showRating = data.json();
    })
  }
  competencyList() {
    this.apiService.dispAllCompetencyList({ token: this.user.token }).subscribe(data => {
      this.competencylist = data.json();
    });
  }
  activate_competency(event, ind, selected) {
    if (this.urltext != 'Edit' || this.urltext == 'Edit') {
      //  $("input[value='" + this.data_id + "']").prop('checked', true).trigger("change");
      var index = this.getcom.findIndex(x => x.competency_id == selected._id);
      if (index == -1) {
        var temindex = this.temparr.findIndex(x => x.competency_id == this.data_id);
        this.getcom.push({ competency_id: selected._id, question_index: [] })
        if (temindex != -1) {
          this.temparr.forEach(element => {
            if (selected._id.indexOf(element.competency_id) > -1) {
              element.question_index.forEach(elements => {
                this.getcom.filter(function (competencies) {
                  if (selected._id.indexOf(competencies.competency_id) > -1) {
                    competencies.question_index.push(elements)
                  }
                })
              });
            }
          });
        }
      }
    }
    this.selected_comp = selected;
    this.data_id = selected._id;
    this.getquestionlist();
    var queind;
    $('#selectalldisable').prop('checked', false);

    if (event.target.checked == true || event.target.accessKey == "") {
      if (this.temparr.length > 0) {

        this.checkingvalues = this.temparr.filter(function (competencies) {

          if (selected._id.indexOf(competencies.competency_id) > -1) {

            return competencies.question_index;

          } 
        });
        this.checkingvalues.forEach(element => {
          element.question_index.forEach(elements => {
            $("input[value='" + elements + "']").prop('disabled', true);
            setTimeout(() => {
              $("input[value='" + elements + "']").prop('checked', true);

            }, 100);

          });
        });
      }
      if (this.competencydetails) {
        this.getcom = this.competencydetails

        this.selectedItems = this.competencydetails.filter(function (competencies) {
          if (selected._id.indexOf(competencies.competency_id) > -1) {
            queind = competencies.question_index;
          } else {
            return false;
          }
        });
        this.setindex = queind

      }
      this.questionind = [];
      var index = this.getcom.findIndex(x => x.competency_id == this.data_id);
      if (event.target.checked && this.urltext != 'Edit' && index == -1) {
        this.getcom.push({ competency_id: selected._id, question_index: [] })
      } else {
        if (event.target.checked && this.urltext == 'Edit' && index == -1) {
          this.getcom.indexOf(this.getcom) === -1 ? this.getcom.push({ competency_id: selected._id, question_index: [] }) : "";
        }
      }
      $('.newsale_box').find('.activedic').removeClass("activedic");
      $(event.target).addClass('activedic');
    } else {
      this.selected_comp = true;
      this.getcom = this.getcom.filter(item =>
        item.competency_id !== selected._id);
    }

  }
  getquestionlist() {
    this.apiService.apirequest('/getAllquestion', { token: this.user.token, data_id: this.data_id,'type':'all' }).subscribe(data => {
      if (data.json().status) {
        this.questionlist = data.json().data;
      }
    })
  }
  getCheckboxValue(value, m, quesdata) {
    let checkidcom = "unchecked"
    if (value.target.checked == true) {
      $("input[value='" + this.data_id + "']").prop('checked', true).trigger("change");

      var item = ({ competency_id: quesdata.competency_id, question_index: [] })
      this.getcom.filter(function (competencies) {
        if (quesdata.competency_id.indexOf(competencies.competency_id) > -1) {

          return competencies.question_index.push(quesdata._id);

        }
      });
      this.temparr = this.getcom
    } else {
      this.getcom.filter(function (competencies) {
        if (quesdata.competency_id.indexOf(competencies.competency_id) > -1) {
          var rem = competencies.question_index.splice(m, 1)
        }
      });
      this.temparr = this.getcom
    }
  }
  getcompValue(value, i, select) {
    this.comcheckval = value.target.checked;

    if (value.target.checked == false) {

      this.checkingvalues.forEach(element => {
        element.question_index.forEach(elements => {
          setTimeout(() => {
            $("input[value='" + elements + "']").prop('checked', false);

          }, 100);

        });
      });
      var index = this.getcom.map(x => {
        return x.competency_id;
      }).indexOf(select._id);
      this.getcom.splice(index);
      
    }
  }
  numberOnly(event,input): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)&&input==0) {
      return false;
    }
    return true;
  }

  schedulSurvey() {
    if (!$("#survey_form").valid()) {
      $(".multiselect-dropdown").css("border", "1px solid red");
      Swal('Oops...', "Please submit all mantatory fields as required.", 'error');
      $("#survey_popup").modal("show");
    } else {
      
      if (this.getcom.length == 0) {
        Swal('Oops...', "Please select any one competency and questions ", 'error')
      } else {
        console.log('questioninfo', this.questioninfo);
        let filter_data = this.questioninfo.filter(val=>val.question!='');
        console.log('filter data ', filter_data);
        this.apiService.schedulSurvey({ token: this.user.token, userlogin: this.loginuser, data_id: this.dataid, survey_name: this.surveyname, survey_des: this.surveydes, competency: this.getcom, questions_about_survey :filter_data, survey_rating: this.survey_rating, survey_status: this.getstatus }).subscribe(data => {
          this.response = data.json();
          $("#survey_popup").modal("hide");
          if (this.response.status) {
            Swal('Success', this.response.message, 'success');
          }
          else {
            Swal('Oops...', this.response.message, 'error')
          }
        })
        this.router.navigate(['/survey']);

      }
    }
  }
  selectedList;
  checkbox(m, data) {
    if (this.setindex) {
      this.setindex.forEach(element => {
        this.questionind.push(element)
      });
      return (this.setindex.indexOf(data._id) !== -1)
    }
  }
  checkbyid(data, i) {
    return (this.getcompid.indexOf(data._id) !== -1)
  }
  selectallques(evt) {
    if (evt.target.checked) {
      var index = this.getcom.findIndex(x => x.competency_id == this.data_id);
      if (index > -1) {
        if (this.questionlist != '') {
          let checkcomid = this.data_id
          this.questionlist.forEach(element => {
            this.getcom.filter(function (competencies) {
              if (checkcomid.indexOf(competencies.competency_id) > -1) {
                var quesindex = competencies.question_index.findIndex(x => x == element._id);
                if (quesindex == -1) {
                  return competencies.question_index.push(element._id);
                }

              } 
            });
            $("input[value='" + this.data_id + "']").prop('checked', true);
            $("input[value='" + element._id + "']").prop('checked', true);
          });
        }
      } else {
        this.getcom.push({ competency_id: this.data_id, question_index: [] })
        var index = this.getcom.findIndex(x => x.competency_id == this.data_id);
        if (index > -1) {
          if (this.questionlist != '') {
            let checkcomid = this.data_id
            this.questionlist.forEach(element => {
              this.getcom.filter(function (competencies) {
                if (checkcomid.indexOf(competencies.competency_id) > -1) {
                  var quesindex = competencies.question_index.findIndex(x => x == element._id);
                  if (quesindex == -1) {
                    return competencies.question_index.push(element._id);
                  }

                } 
              });
              $("input[value='" + this.data_id + "']").prop('checked', true);
              $("input[value='" + element._id + "']").prop('checked', true);
            });
          }
        }
      }
      this.temparr = this.getcom
    } else {
      let checkcomid = this.data_id
      this.questionlist.forEach(element => {
        this.getcom.filter(function (competencies) {
          if (checkcomid.indexOf(competencies.competency_id) > -1) {
            return competencies.question_index.push(element._id);
          }
        });
        $("input[value='" + element._id + "']").prop('checked', false);
      });
      this.getcom = this.getcom.filter(item =>
        item.competency_id !== checkcomid);
      this.temparr = this.getcom;
    }
  }
}

